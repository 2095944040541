import {
  DELETE_WORKING_LIFE_YEARS_OPTION,
  EDIT_WORKING_LIFE_YEARS_OPTION,
    GET_WORKING_LIFE_YEARS,
    POST_WORKING_LIFE_YEARS_OPTION,
  } from "../constants/actionTypes";

  const defaultState = {
    workingLifeYears:[],
  };

  let data = [];
  export default (state = defaultState, action) => {
    try {
      switch (action.type) {
        case GET_WORKING_LIFE_YEARS:
          return {...state,workingLifeYears:[...action.payload]};
        case POST_WORKING_LIFE_YEARS_OPTION:
          return {...state,workingLifeYears:[...state?.workingLifeYears,action.payload]};
        case EDIT_WORKING_LIFE_YEARS_OPTION:
          data = [...state?.workingLifeYears];
          const index = data?.findIndex(
            (setting) => setting?.life_years_id === action.payload?.life_years_id,
          );
          if (index != -1) data[index] = { ...action?.payload };
          return {...state,workingLifeYears: [...data]};
        case DELETE_WORKING_LIFE_YEARS_OPTION:
          const updatedArr = [...state?.workingLifeYears].filter(
            (setting) => setting.life_years_id !== action.payload,
          );
          return {...state,workingLifeYears: [...updatedArr]};
        default:
          return state;
      }
    } catch (error) {
      throw new Error(error);
    }
  };