import {
  DELETE_DEPRECIATION_SCHEDULE,
  GET_DEPRECIATION_SCHEDULE,
  POST_DEPRECIATION_SCHEDULE,
  PUT_DEPRECIATION_SCHEDULE,
} from "../constants/actionTypes";

const defaultState = {};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_DEPRECIATION_SCHEDULE:
        return { ...action.payload };
      case POST_DEPRECIATION_SCHEDULE:
        return { ...state, ...action.payload };
      case PUT_DEPRECIATION_SCHEDULE:
        return { ...state, ...action.payload };
      case DELETE_DEPRECIATION_SCHEDULE:
        return [...state.filter((data) => data?.id != action?.payload)];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
