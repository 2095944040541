import {
  DELETE_EXPENSE_ACCOUNT_ADJUSTMENT,
  GET_EXPENSE_ACCOUNT_ADJUSTMENT,
  GET_EXPENSE_ACCOUNT_ADJUSTMENT_LIST,
  POST_EXPENSE_ACCOUNT_ADJUSTMENT,
  PUT_EXPENSE_ACCOUNT_ADJUSTMENT,
} from "../constants/actionTypes";

const defaultState = {
  expenseAccountAdjustmentArr:[],
  expenseAccountAdjustment:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_EXPENSE_ACCOUNT_ADJUSTMENT_LIST:
        return {...state,expenseAccountAdjustmentArr:[...action.payload]};
      case GET_EXPENSE_ACCOUNT_ADJUSTMENT:
        return {...state,expenseAccountAdjustment:{...action.payload}};
      case POST_EXPENSE_ACCOUNT_ADJUSTMENT:
        return {...state,expenseAccountAdjustmentArr:[...state?.expenseAccountAdjustmentArr,action.payload]};
      case PUT_EXPENSE_ACCOUNT_ADJUSTMENT:
        data = [...state?.expenseAccountAdjustmentArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,expenseAccountAdjustmentArr: [...data]};
      case DELETE_EXPENSE_ACCOUNT_ADJUSTMENT:
        data = [...state?.expenseAccountAdjustmentArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,expenseAccountAdjustmentArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
