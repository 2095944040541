import {
  DELETE_INTEREST_ADJUSTMENT,
  GET_INTEREST_ADJUSTMENT,
  GET_INTEREST_ADJUSTMENT_LIST,
  POST_INTEREST_ADJUSTMENT,
  PUT_INTEREST_ADJUSTMENT,
} from "../constants/actionTypes";

const defaultState = {
  interestAdjustmentArr:[],
  interestAdjustment:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_INTEREST_ADJUSTMENT_LIST:
        return {...state,interestAdjustmentArr:[...action.payload]};
      case GET_INTEREST_ADJUSTMENT:
        return {...state,interestAdjustment:{...action.payload}};
      case POST_INTEREST_ADJUSTMENT:
        return {...state,interestAdjustmentArr:[...state?.interestAdjustmentArr,action.payload]};
      case PUT_INTEREST_ADJUSTMENT:
        data = [...state?.interestAdjustmentArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,interestAdjustmentArr: [...data]};
      case DELETE_INTEREST_ADJUSTMENT:
        data = [...state?.interestAdjustmentArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,interestAdjustmentArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
