import {
  GET_ERROR_MESSAGE,
  RESET_ERROR_MESSAGE,
  ERROR_STATUS_CODE,
} from "../constants/actionTypes";

const defaultState = {};

let errorData = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ERROR_MESSAGE:
      errorData = { ...state };
      errorData.errorMessage = action.payload;
      return errorData;
    case RESET_ERROR_MESSAGE:
      errorData = { ...state };
      errorData.errorMessage = "";
      return errorData;
    case ERROR_STATUS_CODE:
      errorData = { ...state };
      errorData.statusCode = action.payload;
      return errorData;
    default:
      return state;
  }
};
