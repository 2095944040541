import { GET_IRAS_AUTH_TOKEN, GET_IRAS_AUTH_URL } from "../constants/actionTypes";

const defaultState = {
  authToken: []
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_IRAS_AUTH_URL:
        return { ...state, ...action.payload };
      case GET_IRAS_AUTH_TOKEN:
        return {...state, authToken: [...action.payload]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
