import {
  DELETE_CUSTOMERS,
  GET_CUSTOMERS,
  POST_CUSTOMERS,
  PUT_CUSTOMERS,
} from "../constants/actionTypes";

const defaultState = [];
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_CUSTOMERS:
        return [...action.payload];
      case POST_CUSTOMERS:
        return [...state, action.payload];
      case PUT_CUSTOMERS:
        let data = [...state];
        index = data.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index !== -1) data[index] = { ...action?.payload };
        return data;
      case DELETE_CUSTOMERS:
        const updatedArr = [...state].filter(
          (setting) => setting.id_customer !== action.payload,
        );
        return [...updatedArr];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
