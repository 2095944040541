import {
  DELETE_INTEREST_INCOME,
  GET_INTEREST_INCOME,
  GET_INTEREST_INCOME_LIST,
  POST_INTEREST_INCOME,
  PUT_INTEREST_INCOME,
} from "../constants/actionTypes";

const defaultState = {
  interestIncomeArr:[],
  interestIncome:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_INTEREST_INCOME_LIST:
        return {...state,interestIncomeArr:[...action.payload]};
      case GET_INTEREST_INCOME:
        return {...state,interestIncome:{...action.payload}};
      case POST_INTEREST_INCOME:
        return {...state,interestIncomeArr:[...state?.interestIncomeArr,action.payload]};
      case PUT_INTEREST_INCOME:
        data = [...state?.interestIncomeArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,interestIncomeArr: [...data]};
      case DELETE_INTEREST_INCOME:
        data = [...state?.interestIncomeArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,interestIncomeArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
