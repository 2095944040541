import {
  DELETE_PROVISIONAL_ALLOWANCES,
  GET_PROVISIONAL_ALLOWANCES,
  GET_PROVISIONAL_ALLOWANCES_LIST,
  POST_PROVISIONAL_ALLOWANCES,
  PUT_PROVISIONAL_ALLOWANCES,
} from "../constants/actionTypes";

const defaultState = {
  provisionalAllowancesArr:[],
  provisionalAllowances:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_PROVISIONAL_ALLOWANCES_LIST:
        return {...state,provisionalAllowancesArr:[...action.payload]};
      case GET_PROVISIONAL_ALLOWANCES:
        return {...state,provisionalAllowances:{...action.payload}};
      case POST_PROVISIONAL_ALLOWANCES:
        return {...state,provisionalAllowancesArr:[...state?.provisionalAllowancesArr,action.payload]};
      case PUT_PROVISIONAL_ALLOWANCES:
        data = [...state?.provisionalAllowancesArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,provisionalAllowancesArr: [...data]};
      case DELETE_PROVISIONAL_ALLOWANCES:
        data = [...state?.provisionalAllowancesArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,provisionalAllowancesArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
