import dayjs from "dayjs";

export const formatName = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};
export const capitalizeWords = (str) => {
  return str?.replace(/\b\w/g, char => char.toUpperCase());
};
export const formatDateInDDMMYYY = (d) => {
  return d != null && d != "" ? dayjs(d).format("YYYY-MM-DD") : null;
};
export const dateToShow = (d) => {
  return d != null && d != "" ? dayjs(d).format("DD-MM-YYYY") : null;
};
export const formatDateInSlashDDMMYYY = (d) => {
  return d != null && d != "" ? dayjs(d).format("DD/MM/YYYY") : null;
};
export const errorMessageFromResponse = (resp) => {
  let errorMsg = "";
  if (typeof resp === "object") {
    const errorObj = resp?.response?.data?.error;
    if (errorObj != null) {
      errorMsg = Object.keys(errorObj).includes("message")
        ? errorObj?.message
        : Object.values(errorObj)[0];
    }
  } else if (typeof resp === "string") {
    errorMsg = resp;
  }
  return errorMsg;
};
export const onNumberInput = (e) => {
  let sanitizedValue = e.target.value.replace(/,/g, "").replace(/[^0-9.-]/g, "");

  if (sanitizedValue.includes("-")) {
    sanitizedValue = sanitizedValue.replace(/-/g, ""); 
    sanitizedValue = "-" + sanitizedValue; 
  }

  const parts = sanitizedValue.split(".");
  if (parts.length > 2) {
    sanitizedValue = parts[0] + "." + parts.slice(1).join("");
  }

  if (sanitizedValue === "-" || !isNaN(Number(sanitizedValue))) {
    return {
      ...e,
      target: {
        ...e.target,
        value: sanitizedValue,
      },
    };
  }
};


export const getLastYearDateForFYE = (fye) => {
  const financialYearMonth = new Date(fye).getMonth();
  const rentalStartPeriod = new Date(
    new Date(fye).getFullYear(),
    financialYearMonth - 11,
    1
  );
  return rentalStartPeriod;
};
export const formatNumForExcel = (num) => {
  if (num == null || num === "") return num;
  const formattedNum = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Math.abs(num));
  return num < 0 ? `(${formattedNum})` : formattedNum;
}
export const sortScheduleList = (schedules) =>
  schedules?.sort((a, b) => {
    const schA = a.order;
    const schB = b.order;
    if (schA < schB) return -1;
    if (schA > schB) return 1;
    return 0;
  });
export const validatePercentage = (val) => {
  return val >= 0 && val <= 100;
};
export const validPositiveNumber = (val) => val >= 0;
export const validateEmptyObject = (obj) => {
  let flag = 0;
  Object.values(obj).forEach((val) => {
    if (val == "") flag = 1;
  });
  return flag == 1 ? false : true;
};
export const generateSequence = (prefix, numDigits, startNum, endNum) => {
  var sequence = [];
  for (var i = startNum; i <= endNum; i++) {
    var number = String(i).padStart(numDigits, "0");
    var item = prefix + number;
    sequence.push(item);
  }
  return sequence;
};
export const validateEmptyFields = (obj, fieldsArr) => {
  let flag = 0;
  fieldsArr?.forEach((field) => {
    if (obj[field] === "") {
      flag = 1;
    }
  });
  return flag == 0 ? true : false;
};
export const formatNum = (num) => {
  return num != "" || num != null
    ? new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      }).format(num)
    : num;
};

export const formatInteger= (num) => {
  return num != "" || num != null
    ? new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(num)
    : num;
};

export function isNumber(value) {
  return typeof value === "number";
}
export const isDate = (value) => {
  return /^\d{4}-\d{2}-\d{2}$/.test(value);
};
export const formatDate = (value) =>
  value != null ? dayjs(value).format("DD/MM/YYYY") : "";

export const validationCheck = (arr, item, cols) => {
  let flag = true;
  arr.forEach((t) => {
    const result = cols?.every((col) => {
      return t["id"] != item["id"] && t[col] == item[col];
    });
    if (result == true) {
      flag = false;
    }
  });
  return flag;
};

export const extractDomain = (link) => {
  if (link !== undefined && link !== null) {
    const url = new URL(link);
    let domain = url.hostname;
    let domainArr = domain.split(".");
    let domainName = domainArr[domainArr?.length - 2];
    return domainName;
  }
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " year(s) ";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " month(s) ";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " day(s) ";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hour(s) ";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minute(s) ";
  }
  return Math.floor(seconds) + " second(s) ";
};

export const isValidUrl = (urlString) => {
  let expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  let regex = new RegExp(expression);
  return urlString.match(regex);
};

export const convertDaysToYearsMonthsDays = (days) => {
  const avgDaysInYear = 365;
  const monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Calculate years
  const years = Math.floor(days / avgDaysInYear);

  // Calculate remaining days
  let remainingDays = days - years * avgDaysInYear;

  // Calculate leap years
  let leapYears = 0;
  for (let i = 0; i <= years; i++) {
    if ((i % 4 === 0 && i % 100 !== 0) || i % 400 === 0) {
      leapYears++;
    }
  }

  // Distribute remaining days across months
  let months = 0;
  let currentMonth = 0;
  while (remainingDays > monthLengths[currentMonth]) {
    remainingDays -= monthLengths[currentMonth];
    currentMonth++;
    months++;

    if (currentMonth === 1 && leapYears > 0) {
      remainingDays--;
      leapYears--;
    }
  }

  return `${years} years, ${months} months, ${remainingDays} days`;
};

export const dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor(Math.abs(utc2 - utc1) / _MS_PER_DAY);
};

export const generateDate = (referenceDate, selectedYear) => {
  // Create a Date object from the referenceDate
  const updatedDate = new Date(referenceDate);
  // Set the year to selectedYear - 1
  updatedDate.setFullYear(selectedYear - 1);

  return updatedDate;
}
