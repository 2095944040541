import {
  DELETE_USERS,
  GET_USERS,
  POST_USERS,
  PUT_USERS,
} from "../constants/actionTypes";

const defaultState = [];
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_USERS:
        return [...action.payload];
      case POST_USERS:
        return [...state, action.payload];
      case PUT_USERS:
        let data = [...state];
        index = data?.findIndex(
          (setting) => setting?.id_user === action.payload?.id_user,
        );
        if (index !== -1) data[index] = { ...action?.payload };
        return data;
      case DELETE_USERS:
        const updatedArr = [...state].filter(
          (setting) => setting.id_user !== action.payload,
        );
        return [...updatedArr];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
