import {
  DELETE_TRUST_REIT_DISTRIBUTION,
  GET_TRUST_REIT_DISTRIBUTION,
  GET_TRUST_REIT_DISTRIBUTION_LIST,
  POST_TRUST_REIT_DISTRIBUTION,
  PUT_TRUST_REIT_DISTRIBUTION,
} from "../constants/actionTypes";

const defaultState = {
  trustReitDistributionArr:[],
  trustReitDistribution:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_TRUST_REIT_DISTRIBUTION_LIST:
        return {...state,trustReitDistributionArr:[...action.payload]};
      case GET_TRUST_REIT_DISTRIBUTION:
        return {...state,trustReitDistribution:{...action.payload}};
      case POST_TRUST_REIT_DISTRIBUTION:
        return {...state,trustReitDistributionArr:[...state?.trustReitDistributionArr,action.payload]};
      case PUT_TRUST_REIT_DISTRIBUTION:
        data = [...state?.trustReitDistributionArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,trustReitDistributionArr: [...data]};
      case DELETE_TRUST_REIT_DISTRIBUTION:
        data = [...state?.trustReitDistributionArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,trustReitDistributionArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
