import {
  DELETE_TAX_COMPUTATION_ADJUSTMENTS,
  GET_TAX_COMPUTATION_ADJUSTMENTS,
  GET_TAX_COMPUTATION_ADJUSTMENT,
  POST_TAX_COMPUTATION_ADJUSTMENTS,
  PUT_TAX_COMPUTATION_ADJUSTMENTS,
} from "../constants/actionTypes";

const defaultState = {
  taxComputationAdjustmentsArr: [],
  taxComputationAdjustments : {}
};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_TAX_COMPUTATION_ADJUSTMENTS:
        return {...state,taxComputationAdjustmentsArr:[...action.payload]};
      case GET_TAX_COMPUTATION_ADJUSTMENT:
        return {...state,taxComputationAdjustments:{...action.payload}};
      case POST_TAX_COMPUTATION_ADJUSTMENTS:
        return {...state,taxComputationAdjustmentsArr:[...state?.taxComputationAdjustmentsArr,action.payload]};
      case PUT_TAX_COMPUTATION_ADJUSTMENTS:
        let data = [...state?.taxComputationAdjustmentsArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,taxComputationAdjustmentsArr: [...data]};
      case DELETE_TAX_COMPUTATION_ADJUSTMENTS:
        const updatedArr = [...state].filter(
          (setting) => setting.id !== action.payload,
        );
        return [...updatedArr];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
