import {
  DELETE_EXPENSE_ACCOUNT,
  GET_EXPENSE_ACCOUNT,
  GET_EXPENSE_ACCOUNT_LIST,
  POST_EXPENSE_ACCOUNT,
  PUT_EXPENSE_ACCOUNT,
} from "../constants/actionTypes";

const defaultState = {
  expenseAccountArr:[],
  expenseAccount:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_EXPENSE_ACCOUNT_LIST:
        return {...state,expenseAccountArr:[...action.payload]};
      case GET_EXPENSE_ACCOUNT:
        return {...state,expenseAccount:{...action.payload}};
      case POST_EXPENSE_ACCOUNT:
        return {...state,expenseAccountArr:[...state?.expenseAccountArr,action.payload]};
      case PUT_EXPENSE_ACCOUNT:
        data = [...state?.expenseAccountArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,expenseAccountArr: [...data]};
      case DELETE_EXPENSE_ACCOUNT:
        data = [...state?.expenseAccountArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,expenseAccountArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
