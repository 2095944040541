import {
  DELETE_FRS_116_ADJUSTMENT_DETAILS,
  GET_FRS_116_ADJUSTMENT_DETAILS,
  POST_FRS_116_ADJUSTMENT_DETAILS,
  PUT_FRS_116_ADJUSTMENT_DETAILS,
  GET_FRS_116_ADJUSTMENTS_LIST
} from "../constants/actionTypes";

const defaultState = {
  frs116AdjustmentArr:[],
  frs116Adjustment:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_FRS_116_ADJUSTMENTS_LIST:
        return {...state,frs116AdjustmentArr:[...action.payload]};
      case GET_FRS_116_ADJUSTMENT_DETAILS:
        return {...state,frs116Adjustment:{...action.payload}};
      case POST_FRS_116_ADJUSTMENT_DETAILS:
        return {...state,frs116AdjustmentArr:[...state?.frs116AdjustmentArr,action.payload]};
      case PUT_FRS_116_ADJUSTMENT_DETAILS:
        data = [...state?.frs116AdjustmentArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,frs116AdjustmentArr: [...data]};
      case DELETE_FRS_116_ADJUSTMENT_DETAILS:
        data = [...state?.frs116AdjustmentArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,frs116AdjustmentArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
