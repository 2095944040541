import {
  DELETE_FIXED_ASSETS_DETAILS,
    GET_FIXED_ASSETS_DETAILS,
    GET_FIXED_ASSETS_LIST,
    POST_FIXED_ASSETS_DETAILS,
    PUT_FIXED_ASSETS_DETAILS
  } from "../constants/actionTypes";

  const defaultState = {
    fixedAssetsArr:[],
    fixedAssetsDetail:{}
  };

  let data=[]
  export default (state = defaultState, action) => {
    try {
      switch (action.type) {
        case GET_FIXED_ASSETS_LIST:
          return {...state,fixedAssetsArr:[...action.payload]};
        case GET_FIXED_ASSETS_DETAILS:
          return {...state,fixedAssetsDetail:{...action.payload}};
        case POST_FIXED_ASSETS_DETAILS:
          return {...state,fixedAssetsDetail:[...state?.fixedAssetsArr,action.payload]};
        case PUT_FIXED_ASSETS_DETAILS:
          data = [...state?.fixedAssetsArr];
          const index = data?.findIndex(
            (setting) => setting?.id === action.payload?.id,
          );
          if (index != -1) data[index] = { ...action?.payload };
          return {...state,fixedAssetsArr: [...data]};
        case DELETE_FIXED_ASSETS_DETAILS:
          data = [...state?.fixedAssetsArr];
          const newData = data?.filter(
            (asset) => asset.id!=action.payload,
          );
          return {...state,fixedAssetsArr: [...newData]};
        default:
          return state;
      }
    } catch (error) {
      throw new Error(error);
    }
  };