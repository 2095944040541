import { combineReducers } from "redux";
import settings from "./reducers/settings";
import serviceprovidercompanies from "./reducers/serviceprovidercompanies";
import errors from "./reducers/errors";
import success from "./reducers/success";
import users from "./reducers/users";
import customers from "./reducers/customers";
import taxComputationDetails from "./reducers/taxcomputationdetails";
import profile from "./reducers/profile";
import levels from "./reducers/levels";
import utils from "./reducers/utils";
import scheduleLinks from "./reducers/scheduleLinks";
import tradingMethodSchedulesContent from "./reducers/tradingMethodSchedulesContent";
import profitAndLossStatement from "./reducers/profitAndLossStatement";
import rentalIncome from "./reducers/rentalIncome";
import dividendIncome from "./reducers/dividendIncome";
import foreignTradeIncome from "./reducers/foreignTradeIncome";
import provisionalAllowances from "./reducers/provisionalallowances";
import interestIncome from "./reducers/interestIncome";
import unremittedIncome from "./reducers/unremittedIncome";
import interestAdjustment from "./reducers/interestAdjustment";
import medicalExpenses from "./reducers/medicalexpenses";
import otherIncome from "./reducers/otherincome";
import groupRelief from "./reducers/groupRelief";
import fixedAssets from "./reducers/fixedAssets";
import categories from "./reducers/categories";
import expenseAccount from "./reducers/expenseAccount";
import workingLifeYears from "./reducers/workingLifeYears";
import expenseAccountAdjustment from "./reducers/expenseAccountAdjustment";
import expenseAccountFull from "./reducers/expenseAccountFull";
import trustReitDistribution from "./reducers/trustReitDistribution";
import disposalFixedAssets from "./reducers/disposalFixedAssets";
import carryBackRelief from "./reducers/carryBackRelief";
import renovationAndRefurbishmentWorks from "./reducers/renovationAndRefurbishmentWorks";
import shareholdingTestCapitalAllowances from "./reducers/shareholdingTestCapitalAllowances";
import shareholdingTestUnabsorbedLosses from "./reducers/shareholdingTestUnabsorbedLosses";
import ftcComputation from "./reducers/ftcComputation";
import innovationProjectsWithPartnerInsitutions from "./reducers/innovationProjectsWithPartnerInsitutions";
import researchAndDevelopment from "./reducers/researchAndDevelopment";
import unutilisedItemsBroughtForwardCarriedForward from "./reducers/unutilisedItemsBroughtForwardCarriedForward";
import donations from "./reducers/donations";
import trainingExpenditure from "./reducers/trainingExpenditure";
import intellectualPropertyRights from "./reducers/intellectualPropertyRights";
import intellectualProperty from "./reducers/intellectualProperty";
import furtherOtherDeductions from "./reducers/furtherOtherDeductions";
import taxComputationAdjustments from "./reducers/taxComputationAdjustments";
import frs116Adjustment from "./reducers/frs116Adjustment";
import incomeTaxComputation from "./reducers/incomeTaxComputation";
import formCs from "./reducers/formCs";
import formCsLite from "./reducers/formCsLite";
import formC from "./reducers/formC";
import depreciationSchedule from "./reducers/depreciationSchedule";
import citRebate from "./reducers/citRebate";
import dashboard from "./reducers/dashboard";
import fixedAssetsSummary from "./reducers/fixedAssetsSummary";
import irasTaxAuth from "./reducers/irasTaxAuth";
import investmentHoldingOtherIncome from "./reducers/investmentHoldingOtherIncome";
import investmentHoldingDividendIncome from "./reducers/investmentHoldingDividendIncome";
import investmentHoldingInterestIncome from "./reducers/investmentHoldingInterestIncome";
import investmentHoldingProfitAndLossStatement from "./reducers/investmentHoldingProfitAndLossStatement";
import investmentHoldingRentalIncome from "./reducers/investmentHoldingRentalIncome";
import investmentHoldingDonations from "./reducers/investmentHoldingDonations";
import investmentHoldingMedicalExpenses from "./reducers/investmentHoldingMedicalExpenses";
import investmentHoldingInterestAdjustment from "./reducers/investmentHoldingInterestAdjustment";
import investmentPropertySummary from "./reducers/investmentPropertySummary";
import investmentHoldingFormC from "./reducers/investmentHoldingFormC";


export default combineReducers({
  settings,
  serviceprovidercompanies,
  errors,
  success,
  utils,
  users,
  customers,
  levels,
  profile,
  taxComputationDetails,
  provisionalAllowances,
  unremittedIncome,
  trustReitDistribution,
  unutilisedItemsBroughtForwardCarriedForward,
  interestIncome,
  tradingMethodSchedulesContent,
  profitAndLossStatement,
  rentalIncome,
  furtherOtherDeductions,
  dividendIncome,
  intellectualProperty,
  investmentHoldingMedicalExpenses,
  investmentHoldingInterestAdjustment,
  donations,
  citRebate,
  investmentHoldingFormC,
  trainingExpenditure,
  intellectualPropertyRights,
  foreignTradeIncome,
  interestAdjustment,
  otherIncome,
  expenseAccount,
  investmentHoldingOtherIncome,
  investmentHoldingDividendIncome,
  investmentHoldingInterestIncome,
  investmentPropertySummary,
  expenseAccountAdjustment,
  investmentHoldingRentalIncome,
  investmentHoldingProfitAndLossStatement,
  expenseAccountFull,
  taxComputationAdjustments,
  depreciationSchedule,
  medicalExpenses,
  scheduleLinks,
  fixedAssets,
  categories,
  frs116Adjustment,
  irasTaxAuth,
  incomeTaxComputation,
  renovationAndRefurbishmentWorks,
  workingLifeYears,
  disposalFixedAssets,
  groupRelief,
  carryBackRelief,
  shareholdingTestCapitalAllowances,
  shareholdingTestUnabsorbedLosses,
  ftcComputation,
  innovationProjectsWithPartnerInsitutions,
  researchAndDevelopment,
  formCs,
  investmentHoldingDonations,
  formC,
  formCsLite,
  dashboard,
  fixedAssetsSummary
});
