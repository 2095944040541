import {
  DELETE_TRAINING_EXPENDITURE,
  GET_TRAINING_EXPENDITURE,
  GET_TRAINING_EXPENDITURE_LIST,
  POST_TRAINING_EXPENDITURE,
  PUT_TRAINING_EXPENDITURE,
} from "../constants/actionTypes";

const defaultState = {
  trainingExpenditureArr:[],
  trainingExpenditure:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_TRAINING_EXPENDITURE_LIST:
        return {...state,trainingExpenditureArr:[...action.payload]};
      case GET_TRAINING_EXPENDITURE:
        return {...state,trainingExpenditure:{...action.payload}};
      case POST_TRAINING_EXPENDITURE:
        return {...state,trainingExpenditureArr:[...state?.trainingExpenditureArr,action.payload]};
      case PUT_TRAINING_EXPENDITURE:
        data = [...state?.trainingExpenditureArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,trainingExpenditureArr: [...data]};
      case DELETE_TRAINING_EXPENDITURE:
        data = [...state?.trainingExpenditureArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,trainingExpenditureArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
