import {
  DELETE_CARRY_BACK_RELIEF_DETAILS,
  GET_CARRY_BACK_RELIEF_DETAILS,
  POST_CARRY_BACK_RELIEF_DETAILS,
  PUT_CARRY_BACK_RELIEF_DETAILS,
} from "../constants/actionTypes";

const defaultState = {};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_CARRY_BACK_RELIEF_DETAILS:
        return { losses_carry_back_from:[...action.payload.losses_carry_back_from],losses_carry_back_to:[...action.payload.losses_carry_back_to] };
      case POST_CARRY_BACK_RELIEF_DETAILS:
        return { ...state, ...action.payload };
      case PUT_CARRY_BACK_RELIEF_DETAILS:
        return { ...state, ...action.payload };
      case DELETE_CARRY_BACK_RELIEF_DETAILS:
        return [...state.filter((data) => data?.id != action?.payload)];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
