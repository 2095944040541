import {
  DELETE_CATEGORY_OPTION,
  EDIT_CATEGORY_OPTION,
    GET_CATEGORIES_LIST,
    POST_CATEGORY_OPTION,
  } from "../constants/actionTypes";

  const defaultState = {
    categoriesList:[],
  };

  let data = [];
  export default (state = defaultState, action) => {
    try {
      switch (action.type) {
        case GET_CATEGORIES_LIST:
          return {...state,categoriesList:[...action.payload]};
        case POST_CATEGORY_OPTION:
          return {...state,categoriesList:[...state?.categoriesList,action.payload]};
        case EDIT_CATEGORY_OPTION:
          data = [...state?.categoriesList];
          const index = data?.findIndex(
            (setting) => setting?.category_id === action.payload?.category_id,
          );
          if (index != -1) data[index] = { ...action?.payload };
          return {...state,categoriesList: [...data]};
        case DELETE_CATEGORY_OPTION:
          const updatedArr = [...state?.categoriesList].filter(
            (setting) => setting.category_id !== action.payload,
          );
          return {...state,categoriesList: [...updatedArr]};
        default:
          return state;
      }
    } catch (error) {
      throw new Error(error);
    }
  };