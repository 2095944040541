import {
  DELETE_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD,
  GET_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD,
  POST_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD,
  PUT_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD,
} from "../constants/actionTypes";

const defaultState = {};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD:
        return { ...action.payload };
      case POST_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD:
        return { ...state, ...action.payload };
      case PUT_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD:
        return { ...state, ...action.payload };
      case DELETE_UNUTILISED_ITEMS_BROUGHT_FORWARD_CARRIED_FORWARD:
        return [...state.filter((data) => data?.id != action?.payload)];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
