import {
  DELETE_INVESTMENT_HOLDING_PROFIT_AND_LOSS_STATEMENT,
  GET_INVESTMENT_HOLDING_PROFIT_AND_LOSS_STATEMENT,
  POST_INVESTMENT_HOLDING_PROFIT_AND_LOSS_STATEMENT,
  PUT_INVESTMENT_HOLDING_PROFIT_AND_LOSS_STATEMENT,
} from "../constants/actionTypes";

const defaultState = {};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_INVESTMENT_HOLDING_PROFIT_AND_LOSS_STATEMENT:
        return { ...action.payload };
      case POST_INVESTMENT_HOLDING_PROFIT_AND_LOSS_STATEMENT:
        return { ...state, ...action.payload };
      case PUT_INVESTMENT_HOLDING_PROFIT_AND_LOSS_STATEMENT:
        return { ...state, ...action.payload };
      case DELETE_INVESTMENT_HOLDING_PROFIT_AND_LOSS_STATEMENT:
        return [...state.filter((data) => data?.id != action?.payload)];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
