import {
  DELETE_INVESTMENT_HOLDING_MEDICAL_EXPENSES,
  GET_INVESTMENT_HOLDING_MEDICAL_EXPENSES,
  GET_INVESTMENT_HOLDING_MEDICAL_EXPENSES_LIST,
  POST_INVESTMENT_HOLDING_MEDICAL_EXPENSES,
  PUT_INVESTMENT_HOLDING_MEDICAL_EXPENSES,
} from "../constants/actionTypes";

const defaultState = {
  medicalExpensesArr:[],
  medicalExpenses:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_INVESTMENT_HOLDING_MEDICAL_EXPENSES_LIST:
        return {...state,medicalExpensesArr:[...action.payload]};
      case GET_INVESTMENT_HOLDING_MEDICAL_EXPENSES:
        return {...state,medicalExpenses:{...action.payload}};
      case POST_INVESTMENT_HOLDING_MEDICAL_EXPENSES:
        return {...state,medicalExpensesArr:[...state?.medicalExpensesArr,action.payload]};
      case PUT_INVESTMENT_HOLDING_MEDICAL_EXPENSES:
        data = [...state?.medicalExpensesArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,medicalExpensesArr: [...data]};
      case DELETE_INVESTMENT_HOLDING_MEDICAL_EXPENSES:
        data = [...state?.medicalExpensesArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,medicalExpensesArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
