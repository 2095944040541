import {
      GET_FORM_CS_LIST,
      POST_FORM_CS,
      PUT_FORM_CS
    } from "../constants/actionTypes";
  
    const defaultState = {
        formCsList: []
    };
  
    export default (state = defaultState, action) => {
      try {
        switch (action.type) {
          case GET_FORM_CS_LIST:
            return {...state,formCsList:[...action.payload]};
          case POST_FORM_CS:
            return {...state,formCsList:[action.payload]};
          case PUT_FORM_CS:
            return {...state,formCsList: [...action.payload]};
          default:
            return state;
        }
      } catch (error) {
        throw new Error(error);
      }
    };