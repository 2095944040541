import {
  ASK_TO_CONTINUE,
  DELETE_PROFILE,
  GET_PROFILE,
  LOGIN_USER,
  LOGOUT_USER,
  POST_PROFILE,
  PUT_PROFILE,
} from "../constants/actionTypes";

const defaultState = {
  isLoggedIn: false,
  lastLoggedInTime: null,
  profileData: {},
  showExpiredModal: false,
};

let profileData = {};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_PROFILE:
        return { ...state, profileData: { ...action.payload } };
      case POST_PROFILE:
        return { ...profileData, ...action.payload };
      case PUT_PROFILE:
        //   let data = {...profileData};
        //   index = profileData.findIndex(
        //     (profileDetails) => profileDetails?.id_user === action.payload?.id_user,
        //   );
        //   if (index !== -1) data[index] = { ...action?.payload };
        return { ...state, profileData: { ...action?.payload } };
      case DELETE_PROFILE:
        const updatedArr = profileData.filter(
          (profileDetails) => profileDetails.id_user !== action.payload,
        );
        return [...updatedArr];
      case LOGIN_USER:
        profileData = { profileData: { ...action.payload }, isLoggedIn: true, lastLoggedInTime: Date.now() };
        return profileData;
      case LOGOUT_USER:
        profileData = { isLoggedIn: false, profileData: {}, lastLoggedInTime: null };
        return profileData;
      case ASK_TO_CONTINUE:
        return { ...profileData, showExpiredModal: true };
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
