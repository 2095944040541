import {
  DELETE_SCHEDULE_LINKS,
  GET_SCHEDULE_LINKS,
  POST_SCHEDULE_LINKS,
  PUT_SCHEDULE_LINKS,
} from "../constants/actionTypes";

const defaultState = [];
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_SCHEDULE_LINKS:
        return [...action.payload];
      case POST_SCHEDULE_LINKS:
        return [...action.payload];
      case PUT_SCHEDULE_LINKS:
        return [...action.payload];
      case DELETE_SCHEDULE_LINKS:
        return [
          ...state.filter((data) => data?.schedule_id != action?.payload),
        ];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
