import {
  DELETE_INVESTMENT_HOLDING_INTEREST_INCOME,
  GET_INVESTMENT_HOLDING_INTEREST_INCOME,
  POST_INVESTMENT_HOLDING_INTEREST_INCOME,
  PUT_INVESTMENT_HOLDING_INTEREST_INCOME,
} from "../constants/actionTypes";

const defaultState = {}
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_INVESTMENT_HOLDING_INTEREST_INCOME:
        return {...state,...action.payload};
      case POST_INVESTMENT_HOLDING_INTEREST_INCOME:
        return {...action.payload};
      case PUT_INVESTMENT_HOLDING_INTEREST_INCOME:
        return {...state,...action.payload};
      case DELETE_INVESTMENT_HOLDING_INTEREST_INCOME:
        data = [...state.interestIncomeArr];
        const newData = data?.filter(
          (income) => income.id != action.payload,
        );
        return {...state, interestIncomeArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
