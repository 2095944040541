import {
    DELETE_FIXED_ASSETS_DETAILS,
      GET_FIXED_ASSETS_DETAILS,
      GET_FIXED_ASSETS_LIST,
      GET_FTC_CALCULATION,
      POST_FTC_CALCULATION,
      PUT_FIXED_ASSETS_DETAILS,
      PUT_FTC_CALCULATION
    } from "../constants/actionTypes";

    const defaultState = {
      ftcPoolingDetails:{}
    };

    let data=[]
    export default (state = defaultState, action) => {
      try {
        switch (action.type) {
        //   case GET_FIXED_ASSETS_LIST:
        //     return {...state,fixedAssetsArr:[...action.payload]};
          case GET_FTC_CALCULATION:
            return {...state,ftcPoolingDetails:{...action.payload[0]}};
          case POST_FTC_CALCULATION:
            return {...state,ftcPoolingDetails:{...action.payload}};
          case PUT_FTC_CALCULATION:
            return {...state,ftcPoolingDetails:{...action.payload}};

        //   case DELETE_FIXED_ASSETS_DETAILS:
        //     data = [...state?.fixedAssetsArr];
        //     const newData = data?.filter(
        //       (asset) => asset.id!=action.payload,
        //     );
        //     return {...state,fixedAssetsArr: [...newData]};
          default:
            return state;
        }
      } catch (error) {
        throw new Error(error);
      }
    };