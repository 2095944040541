import {
  DELETE_GROUP_RELIEF_DETAILS,
  GET_GROUP_RELIEF_DETAILS,
  POST_GROUP_RELIEF_DETAILS,
  PUT_GROUP_RELIEF_DETAILS,
} from "../constants/actionTypes";

const defaultState = {};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_GROUP_RELIEF_DETAILS:
        console.log("COMING HERE",state,action.payload)
        return { transferor_company:[...action.payload.transferor_company],claimant_company:[...action.payload.claimant_company] };
      case POST_GROUP_RELIEF_DETAILS:
        return { ...state, ...action.payload };
      case PUT_GROUP_RELIEF_DETAILS:
        return { ...state, ...action.payload };
      case DELETE_GROUP_RELIEF_DETAILS:
        return [...state.filter((data) => data?.id != action?.payload)];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
