import {
      GET_INVESTMENT_HOLDING_FORM_C_LIST,
      POST_INVESTMENT_HOLDING_FORM_C,
      PUT_INVESTMENT_HOLDING_FORM_C
    } from "../constants/actionTypes";
  
    const defaultState = {
        formCList: []
    };
  
    export default (state = defaultState, action) => {
      try {
        switch (action.type) {
          case GET_INVESTMENT_HOLDING_FORM_C_LIST:
            return {...state,formCList:[...action.payload]};
          case POST_INVESTMENT_HOLDING_FORM_C:
            return {...state,formCList:[action.payload]};
          case PUT_INVESTMENT_HOLDING_FORM_C:
            return {...state,formCList: [...action.payload]};
          default:
            return state;
        }
      } catch (error) {
        throw new Error(error);
      }
    };