import {
  GET_TAX_SETTINGS,
  POST_TAX_SETTINGS,
  UPDATE_TAX_SETTINGS,
} from "../constants/actionTypes";

const defaultState = [];

let settingsData = [];

let index = "";

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_TAX_SETTINGS:
      settingsData = [...action.payload];
      return settingsData;
    case POST_TAX_SETTINGS:
      index = settingsData.findIndex(function (setting) {
        return setting.id === action.payload.id;
      });
      settingsData[index] = { ...settingsData[index], ...action.payload };
    case UPDATE_TAX_SETTINGS:
      let data = [...settingsData];
      index = settingsData.findIndex(
        (setting) => setting?.id === action.payload?.id,
      );
      if (index !== -1) data[index] = { ...action?.payload };
      return data;
    default:
      return state;
  }
};
