import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "@redux-devtools/extension";
import reducer from "./reducer";
import { thunk } from "redux-thunk";

const getMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(thunk);
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(createLogger(), thunk);
  }
};

export const store = createStore(reducer, composeWithDevTools(getMiddleware()));
