import {
  DELETE_RENTAL_INCOME,
  GET_RENTAL_INCOME,
  GET_RENTAL_INCOME_LIST,
  POST_RENTAL_INCOME,
  PUT_RENTAL_INCOME,
} from "../constants/actionTypes";

const defaultState = {
  rentalIncomeArr:[],
  rentalIncome:{}
};
let data = []

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_RENTAL_INCOME_LIST:
        return {...state,rentalIncomeArr:[...action.payload]};
      case GET_RENTAL_INCOME:
        return {...state,rentalIncome:{...action.payload}};
      case POST_RENTAL_INCOME:
        return {...state,rentalIncomeArr:[...state?.rentalIncomeArr,action.payload]};
      case PUT_RENTAL_INCOME:
        data = [...state?.rentalIncomeArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,rentalIncomeArr: [...data]};
      case DELETE_RENTAL_INCOME:
        data = [...state?.rentalIncomeArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,rentalIncomeArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
