import {
  START_LOADER,
  STOP_LOADER,
  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SHOW_TIME_EXPIRED_MODAL,
  HIDE_TIME_EXPIRED_MODAL,
} from "../constants/actionTypes";
let defaultState = {
  isLoading: false,
  sidebarShow: true,
  unfoldable: false,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case START_LOADER:
      return { ...state, isLoading: true };
    case STOP_LOADER:
      return { ...state, isLoading: false };
    case SHOW_SIDEBAR:
      return { ...state, sidebarShow: true, unfoldable: false };
    case HIDE_SIDEBAR:
      return { ...state, sidebarShow: false, unfoldable: true };
    case SHOW_TIME_EXPIRED_MODAL:
      return { ...state, sidebarShow: true, showModal: true };
    case HIDE_TIME_EXPIRED_MODAL:
      return { ...state, sidebarShow: false, showModal: false };
    default:
      return state;
  }
};
