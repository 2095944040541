import {
  DELETE_EXPENSE_ACCOUNT_FULL,
  GET_EXPENSE_ACCOUNT_FULL,
  GET_EXPENSE_ACCOUNT_FULL_LIST,
  POST_EXPENSE_ACCOUNT_FULL,
  PUT_EXPENSE_ACCOUNT_FULL,
} from "../constants/actionTypes";

const defaultState = {
  expenseAccountFullArr:[],
  expenseAccountFull:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_EXPENSE_ACCOUNT_FULL_LIST:
        return {...state,expenseAccountFullArr:[...action.payload]};
      case GET_EXPENSE_ACCOUNT_FULL:
        return {...state,expenseAccountFull:{...action.payload}};
      case POST_EXPENSE_ACCOUNT_FULL:
        return {...state,expenseAccountFullArr:[...state?.expenseAccountFullArr,action.payload]};
      case PUT_EXPENSE_ACCOUNT_FULL:
        data = [...state?.expenseAccountFullArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,expenseAccountFullArr: [...data]};
      case DELETE_EXPENSE_ACCOUNT_FULL:
        data = [...state?.expenseAccountFullArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,expenseAccountFullArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
