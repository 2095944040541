import {
  DELETE_FOREIGN_TRADE_INCOME,
  GET_FOREIGN_TRADE_INCOME,
  GET_FOREIGN_TRADE_INCOME_LIST,
  POST_FOREIGN_TRADE_INCOME,
  PUT_FOREIGN_TRADE_INCOME,
} from "../constants/actionTypes";

const defaultState = {
  foreignTradeIncomeArr:[],
  foreignTradeIncome:{}
};

let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_FOREIGN_TRADE_INCOME_LIST:
        return {...state,foreignTradeIncomeArr:[...action.payload]};
      case GET_FOREIGN_TRADE_INCOME:
        return {...state,foreignTradeIncome:{...action.payload}};
      case POST_FOREIGN_TRADE_INCOME:
        return {...state,foreignTradeIncomeArr:[...state?.foreignTradeIncomeArr,action.payload]};
      case PUT_FOREIGN_TRADE_INCOME:
        data = [...state?.foreignTradeIncomeArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,foreignTradeIncomeArr: [...data]};
      case DELETE_FOREIGN_TRADE_INCOME:
        data = [...state?.foreignTradeIncomeArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,foreignTradeIncomeArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
