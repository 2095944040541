import {
  DELETE_FURTHER_OTHER_DEDUCTIONS,
  GET_FURTHER_OTHER_DEDUCTIONS,
  GET_FURTHER_OTHER_DEDUCTIONS_LIST,
  POST_FURTHER_OTHER_DEDUCTIONS,
  PUT_FURTHER_OTHER_DEDUCTIONS,
} from "../constants/actionTypes";

const defaultState = {
  furtherOtherDeductionsArr:[],
  furtherOtherDeductions:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_FURTHER_OTHER_DEDUCTIONS_LIST:
        return {...state,furtherOtherDeductionsArr:[...action.payload]};
      case GET_FURTHER_OTHER_DEDUCTIONS:
        return {...state,furtherOtherDeductions:{...action.payload}};
      case POST_FURTHER_OTHER_DEDUCTIONS:
        return {...state,furtherOtherDeductionsArr:[...state?.furtherOtherDeductionsArr,action.payload]};
      case PUT_FURTHER_OTHER_DEDUCTIONS:
        data = [...state?.furtherOtherDeductionsArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,furtherOtherDeductionsArr: [...data]};
      case DELETE_FURTHER_OTHER_DEDUCTIONS:
        data = [...state?.furtherOtherDeductionsArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,furtherOtherDeductionsArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
