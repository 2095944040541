import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CSpinner } from "@coreui/react";
import "./scss/style.scss";
import { loginUser } from "./redux/actions/profile";
import { LOGIN_USER } from "./redux/constants/actionTypes";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(
  () => import("./views/pages/login/ForgotPassword"),
);
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  const isLoggedIn = useSelector((state) => state?.profile?.isLoggedIn);
  //const isLoggedIn = true
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) {
      const token = localStorage?.getItem("token");
      const userdata = localStorage?.getItem("UserId");
      if (token != null) {
        dispatch({ type: LOGIN_USER, payload: {} });
      }
    }
  }, [isLoggedIn]);
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password Page"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={<Register />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="*"
            name="Home"
            element={<DefaultLayout isLoggedIn={isLoggedIn} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

// const ProtectedRoute = ({ component: Component, ...rest }) => {
//   const isLoggedIn = useSelector(state=>state?.profile?.isLoggedIn)
//     return(<Route {...rest} render={props => (
//       isLoggedIn ?
//         <Component {...props} /> :
//         <Redirect to="/login" />
//     )} />
//   );
// };
export default App;
