import {
  DELETE_TRADING_METHOD_SCHEDULE_CONTENT,
  GET_TRADING_METHOD_SCHEDULE_CONTENT,
  POST_TRADING_METHOD_SCHEDULE_CONTENT,
  PUT_TRADING_METHOD_SCHEDULE_CONTENT,
} from "../constants/actionTypes";

const defaultState = [];
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_TRADING_METHOD_SCHEDULE_CONTENT:
        return [...action.payload];
      case POST_TRADING_METHOD_SCHEDULE_CONTENT:
        return [...action.payload];
      case PUT_TRADING_METHOD_SCHEDULE_CONTENT:
        return [...action.payload];
      case DELETE_TRADING_METHOD_SCHEDULE_CONTENT:
        return [
          ...state.filter((data) => data?.schedule_id != action?.payload),
        ];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
