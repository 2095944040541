import {
  DELETE_INTELLECTUAL_PROPERTY,
  GET_INTELLECTUAL_PROPERTY,
  GET_INTELLECTUAL_PROPERTY_LIST,
  POST_INTELLECTUAL_PROPERTY,
  PUT_INTELLECTUAL_PROPERTY,
} from "../constants/actionTypes";

const defaultState = {
  intellectualPropertyArr:[],
  intellectualProperty:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_INTELLECTUAL_PROPERTY_LIST:
        return {...state,intellectualPropertyArr:[...action.payload]};
      case GET_INTELLECTUAL_PROPERTY:
        return {...state,intellectualProperty:{...action.payload}};
      case POST_INTELLECTUAL_PROPERTY:
        return {...state,intellectualPropertyArr:[...state?.intellectualPropertyArr,action.payload]};
      case PUT_INTELLECTUAL_PROPERTY:
        data = [...state?.intellectualPropertyArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,intellectualPropertyArr: [...data]};
      case DELETE_INTELLECTUAL_PROPERTY:
        data = [...state?.intellectualPropertyArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,intellectualPropertyArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
