import {
  DELETE_DIVIDEND_INCOME,
  GET_DIVIDEND_INCOME,
  GET_DIVIDEND_INCOME_LIST,
  POST_DIVIDEND_INCOME,
  PUT_DIVIDEND_INCOME,
} from "../constants/actionTypes";

const defaultState = {
  dividendIncomeArr:[],
  dividendIncome:{}
}
let data=[]

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_DIVIDEND_INCOME_LIST:
        return {...state,dividendIncomeArr:[...action.payload]};
      case GET_DIVIDEND_INCOME:
        return {...state,dividendIncome:{...action.payload}};
      case POST_DIVIDEND_INCOME:
        return {...state,dividendIncomeArr:[...state?.dividendIncomeArr,action.payload]};
      case PUT_DIVIDEND_INCOME:
        data = [...state?.dividendIncomeArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,dividendIncomeArr: [...data]};
      case DELETE_DIVIDEND_INCOME:
        data = [...state?.dividendIncomeArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,dividendIncomeArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
