import {
  DELETE_CIT_REBATE,
  PUT_CIT_REBATE,
    GET_CIT_REBATE,
    POST_CIT_REBATE,
  } from "../constants/actionTypes";

  const defaultState = {
    citRebate:[],
  };

  let data = [];
  export default (state = defaultState, action) => {
    try {
      switch (action.type) {
        case GET_CIT_REBATE:
          return {...state,citRebate:[...action.payload]};
        case POST_CIT_REBATE:
          return {...state,citRebate:[...state?.citRebate,action.payload]};
        case PUT_CIT_REBATE:
          data = [...state?.citRebate];
          const index = data?.findIndex(
            (setting) => setting?.life_years_id === action.payload?.life_years_id,
          );
          if (index != -1) data[index] = { ...action?.payload };
          return {...state,citRebate: [...data]};
        case DELETE_CIT_REBATE:
          const updatedArr = [...state?.citRebate].filter(
            (setting) => setting.life_years_id !== action.payload,
          );
          return {...state,citRebate: [...updatedArr]};
        default:
          return state;
      }
    } catch (error) {
      throw new Error(error);
    }
  };