import {
      DELETE_PARTNER_INNOVATION,
      GET_PARTNER_INNOVATION_DETAILS,
      GET_PARTNER_INNOVATION_LIST,
      POST_PARTNER_INNOVATION,
      PUT_PARTNER_INNOVATION
    } from "../constants/actionTypes";
  
    const defaultState = {
      partnerInnovationArr:[],
      partnerInnovationDetails:{}
    };
  
    let data=[]
    export default (state = defaultState, action) => {
      try {
        switch (action.type) {
          case GET_PARTNER_INNOVATION_LIST:
            return {...state,partnerInnovationArr:[...action.payload]};
          case GET_PARTNER_INNOVATION_DETAILS:
            return {...state,partnerInnovationDetails:{...action.payload}};
          case POST_PARTNER_INNOVATION:
            return {...state,partnerInnovationDetails:[...state?.partnerInnovationArr,action.payload]};
          case PUT_PARTNER_INNOVATION:
            data = [...state?.partnerInnovationArr];
            const index = data?.findIndex(
              (setting) => setting?.id === action.payload?.id,
            );
            if (index != -1) data[index] = { ...action?.payload };
            return {...state,partnerInnovationArr: [...data]};
          case DELETE_PARTNER_INNOVATION:
            data = [...state?.partnerInnovationArr];
            const newData = data?.filter(
              (asset) => asset.id!=action.payload,
            );
            return {...state,partnerInnovationArr: [...newData]};
          default:
            return state;
        }
      } catch (error) {
        throw new Error(error);
      }
    };