import {
  DELETE_DONATIONS,
  GET_DONATIONS,
  GET_DONATIONS_LIST,
  POST_DONATIONS,
  PUT_DONATIONS,
} from "../constants/actionTypes";

const defaultState = {
  donationsArr:[],
  donations:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_DONATIONS_LIST:
        return {...state,donationsArr:[...action.payload]};
      case GET_DONATIONS:
        return {...state,donations:{...action.payload}};
      case POST_DONATIONS:
        return {...state,donationsArr:[...state?.donationsArr,action.payload]};
      case PUT_DONATIONS:
        data = [...state?.donationsArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,donationsArr: [...data]};
      case DELETE_DONATIONS:
        data = [...state?.donationsArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,donationsArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
