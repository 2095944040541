import {
  DELETE_UNREMITTED_INCOME,
  GET_UNREMITTED_INCOME,
  GET_UNREMITTED_INCOME_LIST,
  POST_UNREMITTED_INCOME,
  PUT_UNREMITTED_INCOME,
} from "../constants/actionTypes";

const defaultState = {
  unremittedIncomeArr:[],
  unremittedIncome:{}
};
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_UNREMITTED_INCOME_LIST:
        return {...state,unremittedIncomeArr:[...action.payload]};
      case GET_UNREMITTED_INCOME:
        return {...state,unremittedIncome:{...action.payload}};
      case POST_UNREMITTED_INCOME:
        return {...state,unremittedIncomeArr:[...state?.unremittedIncomeArr,action.payload]};
      case PUT_UNREMITTED_INCOME:
        data = [...state?.unremittedIncomeArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,unremittedIncomeArr: [...data]};
      case DELETE_UNREMITTED_INCOME:
        data = [...state?.unremittedIncomeArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,unremittedIncomeArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
