import {
    GET_FORM_CS_LITE_LIST,
    POST_FORM_CS_LITE,
    PUT_FORM_CS_LITE
  } from "../constants/actionTypes";

  const defaultState = {
      formCsLiteList: []
  };

  export default (state = defaultState, action) => {
    try {
      switch (action.type) {
        case GET_FORM_CS_LITE_LIST:
          return {...state,formCsLiteList:[...action.payload]};
        case POST_FORM_CS_LITE:
          return {...state,formCsLiteList:[action.payload]};
        case PUT_FORM_CS_LITE:
          return {...state,formCsLiteList: [...action.payload]};
        default:
          return state;
      }
    } catch (error) {
      throw new Error(error);
    }
  };