import {
    DELETE_DISPOSAL_FIXED_ASSETS,
      GET_DISPOSAL_FIXED_ASSETS_DETAILS,
      GET_DISPOSAL_FIXED_ASSETS_LIST,
      POST_DISPOSAL_FIXED_ASSETS,
      PUT_DISPOSAL_FIXED_ASSETS
    } from "../constants/actionTypes";
  
    const defaultState = {
      disposalOfFixedAssetsArr:[],
      disposalFixedAssetsDetail:{}
    };
  
    let data=[]
    export default (state = defaultState, action) => {
      try {
        switch (action.type) {
          case GET_DISPOSAL_FIXED_ASSETS_LIST:
            return {...state,disposalOfFixedAssetsArr:[...action.payload]};
          case GET_DISPOSAL_FIXED_ASSETS_DETAILS:
            return {...state,disposalFixedAssetsDetail:{...action.payload}};
          case POST_DISPOSAL_FIXED_ASSETS:
            return {...state,disposalFixedAssetsDetail:[...state?.disposalOfFixedAssetsArr,action.payload]};
          case PUT_DISPOSAL_FIXED_ASSETS:
            data = [...state?.disposalOfFixedAssetsArr];
            const index = data?.findIndex(
              (setting) => setting?.id === action.payload?.id,
            );
            if (index != -1) data[index] = { ...action?.payload };
            return {...state,disposalOfFixedAssetsArr: [...data]};
          case DELETE_DISPOSAL_FIXED_ASSETS:
            data = [...state?.disposalOfFixedAssetsArr];
            const newData = data?.filter(
              (asset) => asset.id!=action.payload,
            );
            return {...state,disposalOfFixedAssetsArr: [...newData]};
          default:
            return state;
        }
      } catch (error) {
        throw new Error(error);
      }
    };