import {
  DELETE_INCOME_TAX_COMPUTATION,
  GET_INCOME_TAX_COMPUTATION,
  POST_INCOME_TAX_COMPUTATION,
  PUT_INCOME_TAX_COMPUTATION,
} from "../constants/actionTypes";

const defaultState = {};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_INCOME_TAX_COMPUTATION:
        return { ...action.payload };
      case POST_INCOME_TAX_COMPUTATION:
        return { ...state, ...action.payload };
      case PUT_INCOME_TAX_COMPUTATION:
        return { ...state, ...action.payload };
      case DELETE_INCOME_TAX_COMPUTATION:
        return [...state.filter((data) => data?.id != action?.payload)];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
