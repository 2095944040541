import {
  ASK_TO_CONTINUE,
  CHANGE_PASSWORD,
  DELETE_PROFILE,
  GET_PROFILE,
  LOGIN_USER,
  LOGOUT_USER,
  POST_PROFILE,
  PUT_PROFILE,
} from "../constants/actionTypes";
import {
  CHANGE_PASSWORD_API,
  GET_PROFILE_API,
  LOGIN_API,
  LOGOUT_API,
  POST_PROFILE_API,
} from "../constants/apis";
import axios from "../../apiClient";
import {
  handleErrorMessage,
  handleSuccessMessage,
  handleSystemErrorMessage,
} from "./utils";
import { store } from "../store";

export function postProfile(data) {
  return async (dispatch) => {
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(`${POST_PROFILE_API}`, data, {
        headers,
      });
      if (response?.status >= 200 && response?.status <= 310) {
        dispatch({ type: POST_PROFILE, payload: response?.data });
        dispatch(handleSuccessMessage("Profile is created successfully!"));
      } else {
        dispatch(handleErrorMessage(response?.error));
      }
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}

export function updateProfile(data) {
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };
  if (Object.keys(data).includes("groups")) delete data["groups"];
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${POST_PROFILE_API}${data?.id_user}/`,
        data,
        { headers },
      );
      if (response?.status >= 200 && response?.status <= 310) {
        dispatch({ type: PUT_PROFILE, payload: response?.data });
        dispatch(handleSuccessMessage("Profile is updated successfully!"));
      } else {
        dispatch(handleErrorMessage(response?.error));
      }
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}

export function deleteProfile(data) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`${POST_PROFILE_API}${data}/`);
      if (response?.status >= 200 && response?.status <= 310) {
        dispatch({ type: DELETE_PROFILE, payload: response?.data });
        dispatch(handleSuccessMessage("Profile is deleted successfully!"));
      } else {
        dispatch(handleErrorMessage(response?.error));
      }
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}

export function getProfile() {
  return async (dispatch) => {
    try {
      const userdata = localStorage.getItem("UserId");
      const response = await axios.get(`${GET_PROFILE_API}/${userdata}/`);
      if (response?.status >= 200 && response?.status <= 310) {
        dispatch({ type: GET_PROFILE, payload: response?.data });
      } else {
        dispatch(handleErrorMessage(response?.error));
      }
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}

export function loginUser(data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${LOGIN_API}`, data);
      if (response?.status >= 200 && response?.status <= 310) {
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("UserId", JSON.stringify(response?.data?.id_user));
        const timeNow = Date.now();
        localStorage.setItem("lastLoggedInTime", timeNow)
        localStorage.setItem("lastValidatedLoggedInTime", timeNow)
        dispatch({ type: LOGIN_USER, payload: {} });
        dispatch(handleSuccessMessage("User is logged in successfully!"));
      } else {
        dispatch(handleErrorMessage(response?.error));
      }
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}

export function changeUserPassword(data) {
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };
  return async (dispatch) => {
    try {
      const response = await axios.post(`${CHANGE_PASSWORD_API}`, data, {
        headers,
      });
      if (response?.status >= 200 && response?.status <= 310) {
        dispatch(
          loginUser({ username: email, password: data?.new_password }),
        ).then(() =>
          dispatch(
            handleSuccessMessage("The password is changed successfully!"),
          ),
        );
      } else {
        dispatch(handleErrorMessage(response?.error));
      }
    } catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}

export function logoutUser() {
  return async (dispatch) => {
    try {
      const userId = localStorage.getItem("UserId")
      const response = await axios.patch(`${LOGOUT_API}?user_id=${userId}`);
      console.log(response);
      if (response?.status >= 200 && response?.status <= 310) {
        localStorage.removeItem("token");
        localStorage.removeItem("UserId");
        localStorage.removeItem("lastLoggedInTime");
        localStorage.removeItem("lastValidatedLoggedInTime");
        dispatch({ type: LOGOUT_USER, payload: {} });
        dispatch(handleSuccessMessage("User is logged out successfully!"));
      } else {
        dispatch(handleErrorMessage(response?.error));
      }
    } catch (error) {
      const timeNow = Date.now();
      localStorage.setItem("lastLoggedInTime", timeNow);
      localStorage.setItem("lastValidatedLoggedInTime", timeNow);
      dispatch(handleErrorMessage(error));
    }
  };
}

export function askToContinue() {
  return async (dispatch) => {
    try {
        dispatch({ type: ASK_TO_CONTINUE, payload: {} });
      }
    catch (error) {
      dispatch(handleErrorMessage(error));
    }
  };
}
