import {
      DELETE_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES,
      GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_DETAILS,
      GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_LIST,
      POST_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES,
      PUT_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES
    } from "../constants/actionTypes";

    const defaultState = {
      shareholdingTestCapitalAllowancesList:[],
      shareholdingTestCapitalAllowancesDetail:{}
    };

    let data=[]
    export default (state = defaultState, action) => {
      try {
        switch (action.type) {
          case GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_LIST:
            return {...state,shareholdingTestCapitalAllowancesList:[...action.payload]};
          case GET_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES_DETAILS:
            return {...state,shareholdingTestCapitalAllowancesDetail:{...action.payload}};
          case POST_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES:
            return {...state,shareholdingTestCapitalAllowancesDetail:[...state?.shareholdingTestCapitalAllowancesList,action.payload]};
          case PUT_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES:
            data = [...state?.shareholdingTestCapitalAllowancesList];
            const index = data?.findIndex(
              (setting) => setting?.id === action.payload?.id,
            );
            if (index != -1) data[index] = { ...action?.payload };
            return {...state,shareholdingTestCapitalAllowancesList: [...data]};
          case DELETE_SHAREHOLDING_TEST_CAPITAL_ALLOWANCES:
            data = [...state?.shareholdingTestCapitalAllowancesList];
            const newData = data?.filter(
              (asset) => asset.id!=action.payload,
            );
            return {...state,shareholdingTestCapitalAllowancesList: [...newData]};
          default:
            return state;
        }
      } catch (error) {
        throw new Error(error);
      }
    };