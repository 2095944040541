import {
  DELETE_OTHER_INCOME,
  GET_OTHER_INCOME,
  GET_OTHER_INCOME_LIST,
  POST_OTHER_INCOME,
  PUT_OTHER_INCOME,
} from "../constants/actionTypes";

const defaultState = {}
let data=[]
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_OTHER_INCOME_LIST:
        return {...state,otherIncomeArr:[...action.payload]};
      case GET_OTHER_INCOME:
        return {...state,...action.payload};
      case POST_OTHER_INCOME:
        return {...state,otherIncomeArr:[...state?.otherIncomeArr,action.payload]};
      case PUT_OTHER_INCOME:
        return {...state,...action.payload};
      // data = [...state?.otherIncomeArr];
        // index = data?.findIndex(
        //   (setting) => setting?.id_customer === action.payload?.id_customer,
        // );
        // if (index != -1) data[index] = { ...action?.payload };
        // return {...state,otherIncomeArr: [...data]};
      case DELETE_OTHER_INCOME:
        data = [...state?.otherIncomeArr];
        const newData = data?.filter(
          (income) => income.id!=action.payload,
        );
        return {...state,otherIncomeArr: [...newData]};
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
