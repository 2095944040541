import {
    DELETE_SHAREHOLDING_TEST_UNABSORBED_LOSSES,
    GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_DETAILS,
    GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_LIST,
    POST_SHAREHOLDING_TEST_UNABSORBED_LOSSES,
    PUT_SHAREHOLDING_TEST_UNABSORBED_LOSSES
  } from "../constants/actionTypes";

  const defaultState = {
    shareholdingTestUnabsorbedLossesList:[],
    shareholdingTestUnabsorbedLossesDetail:{}
  };

  let data=[]
  export default (state = defaultState, action) => {
    try {
      switch (action.type) {
        case GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_LIST:
          return {...state,shareholdingTestUnabsorbedLossesList:[...action.payload]};
        case GET_SHAREHOLDING_TEST_UNABSORBED_LOSSES_DETAILS:
          return {...state,shareholdingTestUnabsorbedLossesDetail:{...action.payload}};
        case POST_SHAREHOLDING_TEST_UNABSORBED_LOSSES:
          return {...state,shareholdingTestUnabsorbedLossesDetail:[...state?.shareholdingTestUnabsorbedLossesList,action.payload]};
        case PUT_SHAREHOLDING_TEST_UNABSORBED_LOSSES:
          data = [...state?.shareholdingTestUnabsorbedLossesList];
          const index = data?.findIndex(
            (setting) => setting?.id === action.payload?.id,
          );
          if (index != -1) data[index] = { ...action?.payload };
          return {...state,shareholdingTestUnabsorbedLossesList: [...data]};
        case DELETE_SHAREHOLDING_TEST_UNABSORBED_LOSSES:
          data = [...state?.shareholdingTestUnabsorbedLossesList];
          const newData = data?.filter(
            (asset) => asset.id!=action.payload,
          );
          return {...state,shareholdingTestUnabsorbedLossesList: [...newData]};
        default:
          return state;
      }
    } catch (error) {
      throw new Error(error);
    }
  };