import {
    DELETE_RESEARCH_AND_DEVELOPMENT,
    GET_RESEARCH_AND_DEVELOPMENT_DETAILS,
    GET_RESEARCH_AND_DEVELOPMENT_LIST,
    POST_RESEARCH_AND_DEVELOPMENT,
    PUT_RESEARCH_AND_DEVELOPMENT
  } from "../constants/actionTypes";

  const defaultState = {
    researchAndDevelopmentArr:[],
    researchAndDevelopmentDetails:{}
  };

  let data=[]
  export default (state = defaultState, action) => {
    try {
      switch (action.type) {
        case GET_RESEARCH_AND_DEVELOPMENT_LIST:
          return {...state,researchAndDevelopmentArr:[...action.payload]};
        case GET_RESEARCH_AND_DEVELOPMENT_DETAILS:
          return {...state,researchAndDevelopmentDetails:{...action.payload}};
        case POST_RESEARCH_AND_DEVELOPMENT:
          return {...state,researchAndDevelopmentDetails:[...state?.researchAndDevelopmentArr,action.payload]};
        case PUT_RESEARCH_AND_DEVELOPMENT:
          data = [...state?.researchAndDevelopmentArr];
          const index = data?.findIndex(
            (setting) => setting?.id === action.payload?.id,
          );
          if (index != -1) data[index] = { ...action?.payload };
          return {...state,researchAndDevelopmentArr: [...data]};
        case DELETE_RESEARCH_AND_DEVELOPMENT:
          data = [...state?.researchAndDevelopmentArr];
          const newData = data?.filter(
            (asset) => asset.id!=action.payload,
          );
          return {...state,researchAndDevelopmentArr: [...newData]};
        default:
          return state;
      }
    } catch (error) {
      throw new Error(error);
    }
  };