import {
  DELETE_TAX_COMPUTATION_DETAILS,
  GET_TAX_COMPUTATION_DETAILS,
  GET_TAX_COMPUTATION_DETAIL,
  POST_TAX_COMPUTATION_DETAILS,
  PUT_TAX_COMPUTATION_DETAILS,
} from "../constants/actionTypes";

const defaultState = {
  taxComputationDetailArr: [],
  taxComputationDetail : {}
};
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_TAX_COMPUTATION_DETAILS:
        return {...state,taxComputationDetailArr:[...action.payload]};
      case GET_TAX_COMPUTATION_DETAIL:
        return {...state,taxComputationDetail:{...action.payload}};
      case POST_TAX_COMPUTATION_DETAILS:
        return {...state,taxComputationDetailArr:[...state?.taxComputationDetailArr,action.payload]};
      case PUT_TAX_COMPUTATION_DETAILS:
        let data = [...state?.taxComputationDetailArr];
        index = data?.findIndex(
          (setting) => setting?.id_customer === action.payload?.id_customer,
        );
        if (index != -1) data[index] = { ...action?.payload };
        return {...state,taxComputationDetailArr: [...data]};
      case DELETE_TAX_COMPUTATION_DETAILS:
        const updatedArr = [...state].filter(
          (setting) => setting.id !== action.payload,
        );
        return [...updatedArr];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
