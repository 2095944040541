import {
  DELETE_SERVICE_PROVIDER_COMPANIES,
  GET_SERVICE_PROVIDER_COMPANIES,
  POST_SERVICE_PROVIDER_COMPANIES,
  PUT_SERVICE_PROVIDER_COMPANIES,
} from "../constants/actionTypes";

const defaultState = [];
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_SERVICE_PROVIDER_COMPANIES:
        return [...action.payload];
      case POST_SERVICE_PROVIDER_COMPANIES:
        return [...state, action.payload];
      case PUT_SERVICE_PROVIDER_COMPANIES:
        let data = [...state];
        index = data.findIndex(
          (setting) => setting?.spc_id === action.payload?.spc_id,
        );
        if (index !== -1) data[index] = { ...action?.payload };
        return data;
      case DELETE_SERVICE_PROVIDER_COMPANIES:
        const updatedArr = state.filter(
          (setting) => setting.spc_id !== action.payload,
        );
        return [...updatedArr];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
